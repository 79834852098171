import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'
import { KEY_AUTHENTICATED, useLoginMutation } from '../components/api'
import LoginBanner from '../components/common/LoginBanner'
import { unauthNavTo } from '../components/navigation'

const OuterContainer = styled.div`
@media only screen and (max-width: 768px) {
  min-height: 500px;
}
@media only screen and (min-width: 767px) {
  height: 500px;
}
`

// markup
const IndexPage = () => {
  const [credentials, setCredentials] = React.useState({ password1: '', password2: '' })

  const queryClient = useQueryClient()
  const loginMutation = useLoginMutation()

  // onload...
  React.useEffect(() => {
    console.debug('Clearing AUTHENTICATED state... - ' + KEY_AUTHENTICATED())
    queryClient.invalidateQueries(KEY_AUTHENTICATED())
    queryClient.resetQueries(KEY_AUTHENTICATED())
    queryClient.removeQueries(KEY_AUTHENTICATED())
    queryClient.refetchQueries(KEY_AUTHENTICATED())
  }, [queryClient])

  // watch for login success
  React.useEffect(() => {
    if (loginMutation.isSuccess) {
      console.debug('Logged in successfully. navigating to the dashboard...')
      unauthNavTo.dashboardPage()
    }
  }, [loginMutation.isSuccess])

  return (
    <main className="main flex-fill" id="top">
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center no-gutters">
          <div className="col-lg-8 col-xxl-5 py-3">
            <OuterContainer className="card overflow-hidden z-index-1">
              <div className="card-body p-0">
                <div className="row no-gutters h-100">
                  <LoginBanner showRegisterNow={false} />
                  <div className="col-md-7 d-flex flex-center">
                    <div className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-5" style={{ minHeight: '300px' }}>
                      <h3 className="mb-3 font-weight-bold">Password reset</h3>

                      <p>Your password has been updated successfully.</p>

                      {/* form */}
                      <div className="d-flex flex-column flex-grow-1 justify-content-end">

                      <Form.Group className="mb-0">
                          <Button
                            className="btn-block"
                            type="submit"
                            onClick={() => unauthNavTo.loginPage()}
                          >
                            <span>Continue to Login</span>
                            </Button>
                        </Form.Group>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </OuterContainer>

            { process.env.NODE_ENV !== 'production' && <pre>credentials={JSON.stringify(credentials, null, 2)}</pre>}
          </div>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
